//
// user.js
// Use this to write your custom JS
//
import 'htmx.org';
import './custom/product_detail';
import './custom/product_catalog';
import './custom/global';

// zmena jazyka v menu - nutne pouze pro fungovani prepinace na "homepage"
$(document).on('click' , '.language-switcher a', function () {
    setCookie('django_language', $(this).attr('id').split('-').pop(), 14);
});

function setCookie(cname, cvalue, exdays, domain) {
    var d = new Date(),
        expires = "";
    if (exdays) {
        d.setTime(d.getTime() + (exdays*24*60*60*1000));
        expires = "expires=" + d.toUTCString() + "; ";
    }
    document.cookie = cname + "=" + cvalue + "; " + expires + "domain=." + ((typeof domain === 'undefined') ? window.location.hostname.replace('www.', '') : domain) + "; path=/";
}
window.setCookie = setCookie

// náš vlastní typ `data-toggle` pro dynamickou zmněnu na základě změny ve formu
// standardni `form-caption` bete hodnotu z `value`, tato implementace z `data-toggle-value`
const toggles = document.querySelectorAll('[data-toggle="form-caption-data"]');
toggles.forEach((toggle) => {
  toggle.addEventListener('change', function () {
    const target = document.querySelector(toggle.dataset.target);
    const value = toggle.dataset.toggle_value;
    target.innerHTML = value;
  });
});

// it uses setCookie, so it must be here at the bottom
import './custom/first_purchase_form';