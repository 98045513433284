import jQuery from 'jquery';
import './jquery.oska-spinner';

(function ($) {
    var ProductCatalog = window.ProductCatalog = {
        register_pagination_buttons: function register_pagination_buttons() {
            const $pageNavigation = $('#page_navigation');
            $pageNavigation.find("a").on('click', function (e) {
                e.preventDefault()
                const new_page_index = this.getAttribute("href").split('=')[1]
                const params = new URLSearchParams(window.location.search);
                params.set('page', new_page_index);

                const newUrl = window.location.origin
                    + window.location.pathname
                    + '?' + params.toString()

                window.history.pushState({path: newUrl}, '', newUrl);

                $pageNavigation.find(".page-item.active").removeClass("active")
                $(`#page_${new_page_index}`).addClass("active")

                const next_page_html = JSON.parse(document.getElementById('paged_products_html').textContent)[new_page_index - 1];

                htmx.swap("#list_content_container", next_page_html, {swapStyle: 'innerHTML', scrollTarget: '#filters_and_products', show: 'top'},{afterSettleCallback: () => register_pagination_buttons()});
            })
        },
        register_wishlist_buttons: function register_wishlist_buttons(csrfmiddlewaretoken, url) {
            const $wishlistButtons = $('.wishlist-button');
            $wishlistButtons.on('click', function (e) {
                e.preventDefault();  // Prevent the default button action
                const $add_to_wishlist_button = $(this);
                const productId = $add_to_wishlist_button.data('product-id');
                const isFavorite = $add_to_wishlist_button.data('product-is-favourite') === "True";

                // Create product data object
                const product_data = {
                    product: productId,
                    action: isFavorite ? "remove" : "add",
                    csrfmiddlewaretoken: csrfmiddlewaretoken
                };

                // Update button state while waiting for the server response
                $add_to_wishlist_button.prop('disabled', true);

                const ajaxPromise = $.ajax({
                    type: "post",
                    url: url,
                    data: JSON.stringify(product_data),
                    headers: {
                        "content-type": "application/x-www-form-urlencoded",
                        "X-CSRFToken": product_data.csrfmiddlewaretoken
                    },
                    success: function (data) {
                        // Assuming the server responds with the updated wishlist status
                        if (data.hasOwnProperty("is_favorite")) {
                            // Update button icon and data attribute to reflect the new favorite status
                            const newIsFavorite = data.is_favorite;

                            $add_to_wishlist_button.data('product-is-favourite', newIsFavorite ? "True" : "False");
                            $add_to_wishlist_button.find('i').attr('class', newIsFavorite ? 'bi bi-heart-fill' : 'bi bi-heart');

                            // Optionally update button text or tooltip if necessary
                            if (data.wishlist_action_text) {
                                $add_to_wishlist_button.attr('title', data.wishlist_action_text);
                            }

                            // Remove any existing wishlist-specific alert messages near the card
                            $add_to_wishlist_button.closest('.card').siblings('.wishlist-message-wrapper').remove();

                            // Display any related messages returned by the server
                            if (data['messages']) {
                                // Wrap messages in a div for easier targeting
                                const messagesHtml = '<div class="wishlist-message-wrapper">' +
                                    data["messages"].map(msg =>
                                        '<div class="alert alert-cart alert-' + msg['class'] + ' wishlist-message"><div>' + msg['message'] + '</div></div>'
                                    ).join('') +
                                    '</div>';
                                $add_to_wishlist_button.closest('.card').after(messagesHtml);
                            }
                        } else {
                            // Handle failure case if the expected data isn't returned
                            console.error("Failed to update the wishlist status - missing data.");
                        }
                    },
                    complete: function () {
                        // Re-enable button regardless of whether the request was successful
                        $add_to_wishlist_button.prop('disabled', false);
                    },
                    error: function (jqXHR, textStatus, errorThrown) {
                        console.error("Error during wishlist operation: ", textStatus, errorThrown);
                    }
                });

                // Show loading spinner while the request is being processed
                $add_to_wishlist_button.oska_spinner(ajaxPromise);
            });
        }
    }
})(jQuery);