import jQuery from 'jquery';

(function ($) {

    function oska_spinner_default($target, jqXHR){
        const $icon = $target.children("i");

        $target.prop("disabled", true)

        const regex_filter_margin_classes =  /^m.-([0-5]|auto)$/
        const margin_classes = $icon.attr("class").split(/\s+/).filter(c => c.match(regex_filter_margin_classes)).join(" ");

        // using position:absolute in spinner and visibility:hidden in icon will keep the size of button the same.
        const $spinner = $(`<div class="spinner-border spinner-border-sm position-absolute ${margin_classes}"></div>`);
        $icon.css('visibility', 'hidden');

        $spinner.insertBefore($icon)
        jqXHR.always(()=> {
            $target.prop("disabled", false)
            $icon.css('visibility', 'initial');
            $spinner.remove()
        })
    }
    function oska_spinner_select($target, jqXHR){
        $target.css('visibility', 'hidden');
        // using position:absolute in spinner and visibility:hidden in select will keep the size of button the same.
        const $spinner = $(`<div class="spinner-border position-absolute"></div>`);
        $spinner.insertBefore($target)

        jqXHR.always(()=> {
            $target.css('visibility', 'initial');
            $spinner.remove()
        })
    }

    /**
     * @param {jQuery.jqXHR} jqXHR
     */
    $.fn.oska_spinner = function (jqXHR) {
        let $target = $(this);

        if($target.is("select")){
            oska_spinner_select($target, jqXHR)
        } else {
            oska_spinner_default($target, jqXHR)
        }
    }
})(jQuery);
