import jQuery from 'jquery';

(function ($) {
    var FirstPurchaseForm = window.FirstPurchaseForm = {
        run: function (options) {

            let {
                form_selector,
                cookies_data,
                msg_success,
            } = options

            let $block = $('#first_purchase_form_block__js');
            if (!$block.length) {

                for (const cd of cookies_data) {
                    setCookie(cd[0], cd[1], 7);
                }

                $('#'+form_selector).on('submit', function (e) {
                    let $form = $(this),
                        $err_msg = $("#first_purchase_form_block #error_message"),
                        url = $form.attr('action'),
                        data = {};

                    $err_msg.hide();

                    $form.find('input').each(function () {
                        var input = $(this);
                        data[input.attr('name')] = input.val();
                    });

                    $.ajax({
                        url: url,
                        data: data,
                        type: 'POST',
                        complete: function(xhr, status) {
                            const response = xhr.responseJSON;
                            if (response.success) {
                                $("#first_purchase_form_block").html('<p class="text-center alert alert-success">' + msg_success + '</p>');
                            }
                            else {
                                let msg_error = response.errors.join("<br/>");
                                $err_msg.html(msg_error);
                                $err_msg.show();
                            }
                        }
                    });
                    e.preventDefault();
                });
            }
        }
    }
})(jQuery);
